import { withRouter } from "react-router-dom";
import { PrivilegeActions, PrivilegeModules } from "../../data/privileges.enum";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ArrowBack,
  KeyboardArrowDown,
  PlaylistPlay,
  Search as SearchIcon,
} from "@mui/icons-material";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import {
  setRighbarContent,
  toggleRightbar,
  useLayoutDispatch,
  useLayoutState,
} from "../../context/LayoutContext";
import useStyles from "./styles";
import carWashRequestTableColumnConfig from "./data-table/car-wash-request-table-column-config";
import MatrixFilterMobile from "./components/CarWashRequestFilterMobile";
import Swal from "sweetalert2";
import DateFilter from "../../components/DateFilter";
import { getFromDate, getToDate } from "../../helper/list-filters-helper";

// **************************************************************

const CarWashRequestList = (props) => {
  const { auth } = useRoleAuthorization();
  const {
    isLoading,
    httpRequestError: error,
    responseData,
    sendRequest,
  } = useHttpRequest();

  const theme = useTheme();
  const matchMediaQuery = useMediaQuery(theme.breakpoints.down("md"));
  const [matrixList, setMatrixList] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const layoutDispatch = useLayoutDispatch();
  const classes = useStyles();
  const layoutState = useLayoutState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [collectionSize, setCollectionSize] = useState(10);
  const [sortBy, setSortBy] = useState({
    column: undefined,
    direction: undefined,
  });
  const [refresh, setRefresh] = useState(false); // refresh list

  const [lastDayFromRequestDate, setLastDayFromRequestDate] = useState(null);
  const [lastDayToRequestDate, setLastDayToRequestDate] = useState(null);
  const [washedDate, setWashedDate] = useState(null);
  const [requestedDate, setRequestedDate] = useState(null);
  const [checkedDate, setCheckedDate] = useState(null);

  const [optionLeaderList, setOptionLeaderList] = useState([]);
  const [optionLeaderFilter, setOptionLeaderFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const statusList = [
    "PENDING",
    "COMPLETED",
    "APPROVED",
    "REJECTED",
    "NO ACTIONS",
  ];

  // Matrix table column
  const columns = carWashRequestTableColumnConfig({
    allowedViewDetail: auth.checkModulePrivilege(
      PrivilegeModules.car_wash_request,
      PrivilegeActions.view_detail,
    ),
  });

  const mobileFilterProps = {
    statusFilter,
    setStatusFilter,
    statusList,
    optionLeaderList,
    optionLeaderFilter,
    setOptionLeaderFilter,
    lastDayFromRequestDate,
    lastDayToRequestDate,
    setLastDayFromRequestDate,
    lastDayToRequestDate,
    lastDayFromRequestDate,
    setLastDayToRequestDate,
    requestedDate,
    setRequestedDate,
    washedDate,
    setWashedDate,
    checkedDate,
    setCheckedDate,
  };

  // ************************** FUNCTION **********************

  function filter() {
    const filters = {
      text: textFilter.trim(),
      status: statusFilter,
      team: optionLeaderFilter ? optionLeaderFilter.username : undefined,
      lastAtFrom: lastDayFromRequestDate
        ? getFromDate(lastDayFromRequestDate)
        : undefined,
      lastAtTo: lastDayToRequestDate
        ? getToDate(lastDayToRequestDate)
        : undefined,
      requestedAt: requestedDate ? getFromDate(requestedDate) : undefined,
      checkedAt: checkedDate ? getFromDate(checkedDate) : undefined,
      washedAt: washedDate ? getFromDate(washedDate) : undefined,
    };

    sendRequest(
      `/v1/car-wash-request/filter?filters=${JSON.stringify(filters)}
      &page=${page}&pageSize=${pageSize}
      ${
        sortBy?.column && sortBy?.direction
          ? "&orderBy=" + sortBy?.column + "&direction=" + sortBy?.direction
          : ""
      }`,
      "GET",
      {},
    ).then((response) => {
      const matrixs = response.data?.matrixs || [];
      setMatrixList(matrixs);
      setCollectionSize(response.data?.total);
    });
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      // perform search when Enter
      e.preventDefault();
      setRefresh(true);
    }
  }

  function sortByColumn(columnConfig, sortDirection) {
    if (columnConfig.sortField && sortDirection) {
      setSortBy({
        column: columnConfig.sortField,
        direction: sortDirection,
      });
      setRefresh(true);
    }
  }

  function handleRowsPerPageChanged(count) {
    setPageSize(count);
    setRefresh(true);
  }

  function handlePageChanged(page) {
    setPage(page);
    setRefresh(true);
  }

  async function initialData() {
    const response = await sendRequest(
      `/v1/user/get-list-by-role/option_leader`,
      "GET",
      {},
    );

    const list = response.data?.users || [];
    const listFilter = list.filter((i) => i.status);
    setOptionLeaderList(listFilter);

    return listFilter;
  }

  // ************************** HOOK **********************

  useEffect(() => {
    setRefresh(true);
  }, [
    statusFilter,
    optionLeaderFilter,
    lastDayFromRequestDate,
    lastDayToRequestDate,
    washedDate,
    requestedDate,
    checkedDate,
  ]);

  useEffect(() => {
    if (refresh) {
      filter();
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (matchMediaQuery) {
      // set filter right bar
      setRighbarContent(
        layoutDispatch,
        [
          <Typography variant={"h2"} key={"rb-header"}>
            Filter
          </Typography>, // header text
          <MatrixFilterMobile
            key={"rb-body"}
            handleKeyDown={handleKeyDown}
            {...mobileFilterProps}
          />,
        ],
        { size: "xs", backButton: true },
      ); // filter content
    }
  }, [
    matchMediaQuery,
    statusFilter,
    setStatusFilter,
    statusList,
    optionLeaderList,
    optionLeaderFilter,
    setOptionLeaderFilter,
    lastDayFromRequestDate,
    lastDayToRequestDate,
    setLastDayFromRequestDate,
    lastDayToRequestDate,
    lastDayFromRequestDate,
    setLastDayToRequestDate,
    requestedDate,
    setRequestedDate,
    washedDate,
    setWashedDate,
    checkedDate,
    setCheckedDate,
  ]);

  useEffect(() => {
    document.title = "Car Wash Request List";
    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.car_wash_request,
        PrivilegeActions.view_list,
      )
    ) {
      props.history.push("/app/dashboard");
    }

    initialData();
  }, []);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // ************************** RENDER **********************

  const titleRender = (
    <Typography variant={"h1"}>Car Wash Request List</Typography>
  );

  const actionRender = auth.checkModulePrivilege(
    PrivilegeModules.car_wash_request,
    PrivilegeActions.add,
  ) && (
    <Button className={"primary"} onClick={() => props.history.push("card")}>
      New
    </Button>
  );

  const filterRender = (
    <>
      <Grid item xs={10} sm={10} md={2} lg={2}>
        <TextField
          autoComplete={"off"}
          id={"text-searchbox"}
          variant={"outlined"}
          value={textFilter}
          onChange={(e) => setTextFilter(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            startAdornment: (
              <InputAdornment position={"start"}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {!matchMediaQuery ? (
        <>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Autocomplete
              id={"status-filter"}
              value={statusFilter}
              onChange={(e, newValue) => {
                setStatusFilter(newValue);
              }}
              options={statusList}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField {...params} label={"Status"} variant={"outlined"} />
              )}
              popupIcon={<KeyboardArrowDown />}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <Autocomplete
              id={"option-leader-filter"}
              options={optionLeaderList}
              getOptionLabel={(option) => `${option.username}-${option.name}`}
              value={optionLeaderFilter}
              onChange={(e, newValue) => setOptionLeaderFilter(newValue)}
              renderInput={(params) => (
                <TextField {...params} label={"Team"} variant={"outlined"} />
              )}
              popupIcon={<KeyboardArrowDown />}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <DateFilter
              label={"Last Day - From"}
              value={lastDayFromRequestDate}
              maxDate={lastDayToRequestDate}
              onChange={(newValue) => setLastDayFromRequestDate(newValue)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <DateFilter
              label={"Last Day - To"}
              value={lastDayToRequestDate}
              minDate={lastDayFromRequestDate}
              onChange={(newValue) => setLastDayToRequestDate(newValue)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <DateFilter
              label={"Request Date"}
              value={requestedDate}
              onChange={(newValue) => setRequestedDate(newValue)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <DateFilter
              label={"Washed Date"}
              value={washedDate}
              onChange={(newValue) => setWashedDate(newValue)}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2} lg={2}>
            <DateFilter
              label={"Checked Date"}
              value={checkedDate}
              onChange={(newValue) => setCheckedDate(newValue)}
            />
          </Grid>
        </>
      ) : (
        <Grid item xs={2}>
          <IconButton
            onClick={() => toggleRightbar(layoutDispatch)}
            className={classes.drawerToggleBtn}
          >
            {layoutState.isRightbarOpened ? (
              <ArrowBack className={classes.toggleRightBarIcon} />
            ) : (
              <PlaylistPlay className={classes.toggleRightBarIcon} />
            )}
          </IconButton>
        </Grid>
      )}
    </>
  );

  const tableRender = (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <DataTable
        fixedHeader={true}
        persistTableHead={true}
        columns={columns}
        data={matrixList}
        sortServer
        onSort={sortByColumn}
        pagination
        paginationServer
        paginationDefaultPage={page}
        paginationPerPage={pageSize}
        paginationTotalRows={collectionSize}
        onChangeRowsPerPage={(count) => handleRowsPerPageChanged(count)}
        onChangePage={(page) => handlePageChanged(page)}
      />
    </Grid>
  );
  // ************************************************
  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          {titleRender}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
          {actionRender}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid className={"form"}>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              {filterRender}
              {tableRender}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(CarWashRequestList);
