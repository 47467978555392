import React from "react";
import { Grid, InputAdornment, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { KeyboardArrowDown, Search } from "@mui/icons-material";
import DateFilter from "../../../components/DateFilter";

export default function CalendarFilterMobile({
  statusFilter,
  setStatusFilter,
  statusList,
  optionLeaderList,
  optionLeaderFilter,
  setOptionLeaderFilter,
  requestedDate,
  setRequestedDate,
  washedDate,
  setWashedDate,
  checkedDate,
  setCheckedDate,
  lastDayFromRequestDate,
  lastDayToRequestDate,
  setLastDayFromRequestDate,
  setLastDayToRequestDate,
}) {
  return (
    <>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12}>
          <Autocomplete
            id={"status-filter"}
            value={statusFilter}
            onChange={(e, newValue) => {
              setStatusFilter(newValue);
            }}
            options={statusList}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label={"Status"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id={"option-leader-filter"}
            options={optionLeaderList}
            getOptionLabel={(option) => `${option.username}-${option.name}`}
            value={optionLeaderFilter}
            onChange={(e, newValue) => setOptionLeaderFilter(newValue)}
            renderInput={(params) => (
              <TextField {...params} label={"Team"} variant={"outlined"} />
            )}
            popupIcon={<KeyboardArrowDown />}
          />
        </Grid>
        <Grid item xs={12}>
          <DateFilter
            label={"Last Day - From"}
            value={lastDayFromRequestDate}
            maxDate={lastDayToRequestDate}
            onChange={(newValue) => setLastDayFromRequestDate(newValue)}
          />
        </Grid>
        <Grid item xs={12}>
          <DateFilter
            label={"Last Day - To"}
            value={lastDayToRequestDate}
            minDate={lastDayFromRequestDate}
            onChange={(newValue) => setLastDayToRequestDate(newValue)}
          />
        </Grid>
        <Grid item xs={12}>
          <DateFilter
            label={"Request Date"}
            value={requestedDate}
            onChange={(newValue) => setRequestedDate(newValue)}
          />
        </Grid>
        <Grid item xs={12}>
          <DateFilter
            label={"Washed Date"}
            value={washedDate}
            onChange={(newValue) => setWashedDate(newValue)}
          />
        </Grid>
        <Grid item xs={12}>
          <DateFilter
            label={"Checked Date"}
            value={checkedDate}
            onChange={(newValue) => setCheckedDate(newValue)}
          />
        </Grid>
      </Grid>
    </>
  );
}
