import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Typography,
  CircularProgress,
  Grid,
  Button,
  Box,
} from "@mui/material";
import Swal from "sweetalert2";
import "./style/index.css";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useRoleAuthorization } from "../../hooks/useRoleAuthorization";
import { PrivilegeModules, PrivilegeActions } from "../../data/privileges.enum";
import DataTable from "react-data-table-component";
import { Add } from "@mui/icons-material";
import { carItemTableColumnConfig } from "./data-table/car-item-table-column-config";
import _ from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import TextFieldController from "../../components/TextFieldController";
import { useParams } from "react-router-dom";
import SwitchController from "../../components/SwitchController";
import DateController from "../../components/DateController";
import AutocompleteController from "../../components/AutocompleteController";
import ImageUploadPreviewComponent from "../../components/ImageUploadPreviewComponent/ImageUploadPreviewComponent";
import moment from "moment";

// ************************ **********************

const CarWashRequestCard = (props) => {
  // *********** VAR *************
  const { auth } = useRoleAuthorization();
  const {
    isLoading,
    sendRequest,
    httpRequestError: error,
    responseData,
  } = useHttpRequest();
  const methods = useForm({ mode: "all" });
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState,
    trigger,
    watch,
  } = methods;
  const [currentRequest, setCurrentRequest] = useState();
  const { id } = useParams();
  const [optionLeaderUsers, setOptionLeaderUsers] = useState([]);
  const [lstImages, setLstImages] = useState([]);
  const [lstDeletedImages, setLstDeletedImages] = useState([]);
  const [isRequestLoading, setRequestLoading] = useState(true);

  // *********** FUNCTION *************
  const navigateToList = () => {
    if (!id) window.location.href = "#/app/car-wash-request/list";
  };

  const handleImages = async () => {
    const lstImageUrls = [];

    //uploading/deleting Aircon images
    const formData = new FormData();
    for (var i = 0; i < lstImages.length; i++) {
      let file = lstImages[i].image;
      let cloudUrl = lstImages[i].cloudUrl;

      if (lstImages[i].newItem == true) {
        formData.append("file", file);
      } else {
        lstImageUrls.push(cloudUrl);
      }
    }
    let type = "AC";
    let result1 = null;
    let deletedImageNames =
      lstDeletedImages.toString() == ""
        ? undefined
        : lstDeletedImages.toString();

    result1 = await sendRequest(
      `/v1/car-wash-request/upload-image-onto-s3/${deletedImageNames}`,
      "POST",
      formData,
    );

    if (result1?.status === 201) {
      let urlArrayInResponse = result1.data.urlArray;

      for (var j = 0; j < urlArrayInResponse.length; j++) {
        lstImageUrls.push(urlArrayInResponse[j].url);

        let newItem = lstImages.find(
          (element) =>
            element.imageName == urlArrayInResponse[j].fileName &&
            element.newItem == true,
        );
        newItem.cloudUrl = urlArrayInResponse[j].url;
        newItem.newItem = false;
      }
    }

    return lstImageUrls;
  };

  const handleSubmitForm = async (props) => {
    try {
      let res;

      const lstImageUrls = await handleImages();

      const payload = {
        ...props,
        daysToComplete: Number(props.daysToComplete),
        team: props.team.username,
        images: lstImageUrls.toString() || null,
      };

      if (id && currentRequest) {
        res = await sendRequest(`/v1/car-wash-request/${id}`, "PUT", payload);
        await fetchCarWashRequest({ optionLeaders: optionLeaderUsers });
      } else {
        res = await sendRequest(`/v1/car-wash-request`, "POST", payload);
      }

      Swal.fire({
        icon: "success",
        title: "Success",
        text: res?.data?.message,
        willClose: navigateToList,
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err,
      });
    }
  };

  const handleStatusRequest = async ({ status }) => {
    try {
      if (status === "complete" && lstImages.length < 6) {
        throw new Error("Please upload at least six photos.");
      }

      if (currentRequest) {
        const res = await sendRequest(
          `/v1/car-wash-request/${status}/${currentRequest.carWashRequestNo}`,
          "POST",
          {},
        );

        await fetchCarWashRequest({ optionLeaders: optionLeaderUsers });

        Swal.fire({
          icon: "success",
          title: "Success",
          text: res?.data?.message,
          willClose: navigateToList,
        });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err,
      });
    }
  };

  const fetchCarWashRequest = async ({ optionLeaders }) => {
    const res = await sendRequest(`/v1/car-wash-request/${id}`, "GET");
    const request = res.data;
    document.title = `${request.carWashRequestNo} - Car Wash Request Card`;
    setCurrentRequest(request);

    const teamFilter = optionLeaders.find((e) => e.username === request.team);

    const mapData = {
      ...request,
      team: teamFilter,
      requestedAt: moment(request.requestedAt).toDate(),
      lastAt: moment(request.lastAt).toDate(),
    };

    reset(mapData);

    if (
      request.images != undefined &&
      request.images != null &&
      request.images != ""
    ) {
      let retImaArray = request.images.split(",");
      const lstImages = [];
      for (var i = 0; i < retImaArray.length; i++) {
        const id = Math.floor(Math.random() * 10000);
        lstImages.push({
          id: id,
          imageName: "",
          image: {},
          cloudUrl: retImaArray[i],
          newItem: false,
        });
      }

      setLstImages(lstImages);
    }
  };

  const loadOptionLeaderUsers = async () => {
    const response = await sendRequest(
      `/v1/user/get-list-by-role/option_leader`,
      "GET",
      {},
    );

    const list = response.data?.users || [];
    const listFilter = list.filter((i) => i.status);
    setOptionLeaderUsers(listFilter);

    return listFilter;
  };

  const initCarWashRequest = async () => {
    try {
      const optionLeaders = await loadOptionLeaderUsers();
      if (id) {
        await fetchCarWashRequest({ optionLeaders });
      } else {
        reset({ team: null });
      }

      trigger();
    } catch (e) {
    } finally {
      setRequestLoading(false);
    }
  };

  const addImage = (id, imageName, image) => {
    setLstImages((current) => [
      ...current,
      {
        id: id,
        imageName: imageName,
        image: image,
        cloudUrl: "",
        newItem: true,
      },
    ]);
  };

  const removeImage = (id) => {
    var deletedFile = lstImages.filter((element) => {
      return element.id == id;
    })[0];

    if (deletedFile.newItem == false) {
      var deletedFileName = deletedFile.cloudUrl.substring(
        deletedFile.cloudUrl.lastIndexOf("/") + 1,
      );
      setLstDeletedImages((current) => [...current, deletedFileName]);
    }

    setLstImages((current) =>
      current.filter((element) => {
        return element.id != id;
      }),
    );
  };

  // *********** HOOK *************

  useEffect(() => {
    document.title = "New - Car Wash Request Card";
    // redirect to default page if not authorized
    if (
      auth.isPrivilegeDataLoaded() &&
      !auth.checkModulePrivilege(
        PrivilegeModules.car_wash_request,
        PrivilegeActions.view_detail,
      )
    ) {
      props.history.push("/app/dashboard");
    }

    initCarWashRequest();
  }, [id]);

  useEffect(() => {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: responseData?.message,
      });
    }
  }, [error, responseData]);

  // *********** Render *************

  const pageTitleRender = (
    <Typography variant={"h1"}>
      {id && currentRequest
        ? `Car Wash Request Card - ${currentRequest.carWashRequestNo}`
        : "Car Wash Request Card - New"}
    </Typography>
  );

  const editMode = !!id && !!currentRequest;

  const actionRender = auth.checkModulePrivilege(
    PrivilegeModules.car_wash_request,
    PrivilegeActions.edit,
  ) && (
    <>
      {(!editMode || (editMode && currentRequest.status === "PENDING")) && (
        <Button
          className={"primary"}
          type="submit"
          disabled={Object.keys(formState.errors).length !== 0}
        >
          Save
        </Button>
      )}

      {editMode && currentRequest.status === "PENDING" && (
        <Button
          className={"primary"}
          disabled={Object.keys(formState.errors).length !== 0}
          onClick={() => handleStatusRequest({ status: "complete" })}
        >
          Complete
        </Button>
      )}

      {editMode && currentRequest.status === "COMPLETED" && (
        <Button
          className={"primary"}
          onClick={() => handleStatusRequest({ status: "approve" })}
          disabled={Object.keys(formState.errors).length !== 0}
        >
          Approve
        </Button>
      )}

      {editMode && currentRequest.status === "COMPLETED" && (
        <Button
          className={"primary"}
          onClick={() => handleStatusRequest({ status: "reject" })}
          disabled={Object.keys(formState.errors).length !== 0}
        >
          Reject
        </Button>
      )}
    </>
  );

  const generalFormRender = (
    <Box className={"form"}>
      <Grid container spacing={{ xs: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography variant={"h2"}>General</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <TextFieldController
                name={`carNo`}
                label={`Car Plate No.`}
                required
                disabled={editMode && currentRequest.status !== "PENDING"}
                onChange={(e) => {
                  setValue("carNo", e.currentTarget.value?.toUpperCase());
                  trigger("carNo");
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <AutocompleteController
                name={"team"}
                label={"Team"}
                options={optionLeaderUsers}
                getOptionLabel={(option) => {
                  if (option) {
                    return `${option.username}-${option.name}`;
                  }
                }}
                required
                disabled={!!currentRequest}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <TextFieldController
                name={`daysToComplete`}
                label={`Days To Complete`}
                type={"number"}
                disabled={editMode}
                required
              />
            </Grid>
            {editMode && (
              <>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <DateController
                    control={control}
                    value={getValues("requestedAt")}
                    label={"Request Date"}
                    name={"requestedAt"}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <DateController
                    control={control}
                    label={"Last Day"}
                    value={getValues("lastAt")}
                    name={"lastAt"}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <TextFieldController
                    name={`status`}
                    label={`Status`}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <TextFieldController
                    name={`washedBy`}
                    label={`Washed By`}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <DateController
                    control={control}
                    name={`washedAt`}
                    value={getValues("washedAt")}
                    label={`Washed Date`}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <TextFieldController
                    name={`checkedBy`}
                    label={`Checked By`}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4} xl={3}>
                  <DateController
                    control={control}
                    name={`checkedAt`}
                    value={getValues("checkedAt")}
                    label={`Checked Date`}
                    disabled
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  const photoFormRender = editMode && !isRequestLoading && (
    <ImageUploadPreviewComponent
      savedImages={lstImages}
      onAddImage={addImage}
      onRemoveImage={removeImage}
      note="Note: Please upload at lease 6 photos"
      readOnly={
        !(
          (!id &&
            auth.checkModulePrivilege(
              PrivilegeModules.car_wash_request,
              PrivilegeActions.add,
            )) ||
          (id &&
            auth.checkModulePrivilege(
              PrivilegeModules.car_wash_request,
              PrivilegeActions.edit,
            ))
        )
      }
    />
  );

  const formRender = (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {pageTitleRender}
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} className={"action"}>
            <Button
              onClick={() => props.history.push("/app/car-wash-request/list")}
            >
              Cancel
            </Button>
            {actionRender}
          </Grid>

          <Grid item xs={12}>
            {generalFormRender}
          </Grid>

          <Grid item xs={12}>
            {photoFormRender}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );

  return (
    <>
      {isLoading && (
        <Backdrop style={{ zIndex: 1 }} open={isLoading}>
          <CircularProgress color={"inherit"} />
        </Backdrop>
      )}
      {formRender}
    </>
  );
};

export default CarWashRequestCard;
